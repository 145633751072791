import Header from '../components/Header/Header'
import Modal from '../components/Modal/Modal'
import Loading from '../components/Loading/Loading'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import editIcon from '../assets/images/edit-red.svg'

import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { getRoleText } from '../helpers/roles'
import { formatDate } from '../helpers/formatters'
import { validateEmail } from '../helpers/validators'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getProfile, updateProfile } from '../api/users'
import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const Profile = () => {
    useEffect(() => {
        document.title = 'Προφίλ - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
    }, [])

    const { getUserRole, getEmailVerified } = useAuth()
    const isMobile = useMediaQuery('(max-width:768px)')
    const currentRole = getUserRole()
    const [email, setEmail] = useState(null)
    const [editing, setEditing] = useState(false)
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['profile', options],
        queryFn: () => getProfile(options),
    })

    const mutation = useMutation({
        mutationFn: updatedProfile =>
            updateProfile({
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProfile),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['profile'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: <p> Επιτυχής αλλαγή email </p>,
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Κάτι πήγε στραβά! </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const handleEmailUpdate = async () => {
        if (!validateEmail(email)) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Λάθος μορφή email! </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))

            return
        }

        setEditing(false)

        mutation.mutate({
            email: email,
            role: currentRole,
            taxid: data.profile.taxid,
            firstName: data.profile.firstName,
            lastName: data.profile.lastName,
            fatherName: data.profile.fatherName,
            motherName: data.profile.motherName,
            birthYear: data.profile.birthYear || '1900',
        })
    }

    const profileContent = (
        <div className="flex flex-col justify-center items-center w-full md:w-auto max-w-xl md:max-w-none md:my-14 md:mx-8">
            {!getEmailVerified() ? (
                <div className="flex justify-center items-center mb-8 w-full max-w-4xl">
                    <Modal classes="flex flex-col md:flex-row items-center gap-2 w-full max-w-4xl">
                        <div className="border-b-4 md:border-b-0 md:border-r-4 text-xl font-semibold ">
                            <h2 className="p-4 md:p-12 self-center">
                                Επιβεβαίωση
                            </h2>
                        </div>

                        <p className="p-4 text-center break-all md:break-normal md:text-start">
                            Παρακαλώ ακολουθήστε τον σύνδεσμο που έχει σταλθεί
                            στην ηλεκτρονική σας διεύθυνση&nbsp;(
                            {email}) που δηλώσατε κατά την εγγραφή σας, ώστε να
                            ενεργοποιήσετε τον λογαριασμό σας και να
                            ξεκλειδώσετε όλες τις δυνατότητες της πλατφόρμας
                        </p>
                    </Modal>
                </div>
            ) : null}

            <Modal classes="flex flex-col gap-4 w-full max-w-4xl my-5">
                <div className="border-b w-full border-black">
                    <h2 className="text-2xl text-center my-4">
                        {currentRole === 'CITIZEN'
                            ? 'Στοιχεία Δημότη'
                            : 'Στοιχεία Επιχείρησης'}
                    </h2>
                </div>

                <div className="w-full flex flex-col items-center gap-3 my-8">
                    <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                        <div className="w-1/2 h-max flex-auto px-8 my-1">
                            <p className="font-medium">ΑΦΜ</p>
                        </div>
                        <div className="w-1/2 h-max flex-auto">
                            <p className="font-medium text-secondary align-middle">
                                {data.profile.taxid ?? '-'}
                            </p>
                        </div>
                    </div>
                    <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                        <div className="w-1/2 h-max flex-auto px-8 my-1">
                            <p className="font-medium">
                                {currentRole === 'CITIZEN'
                                    ? 'Επώνυμο'
                                    : 'Επωνυμία'}
                            </p>
                        </div>
                        <div className="w-1/2 h-max flex-auto">
                            <p className="font-medium text-secondary">
                                {data.profile.lastName ?? '-'}
                            </p>
                        </div>
                    </div>
                    {currentRole === 'CITIZEN' && (
                        <>
                            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                                <div className="w-1/2 h-max flex-auto px-8 my-1">
                                    <p className="font-medium">Όνομα</p>
                                </div>
                                <div className="w-1/2 h-max flex-auto">
                                    <p className="font-medium text-secondary">
                                        {data.profile.firstName ?? '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                                <div className="w-1/2 h-max flex-auto px-8 my-1">
                                    <p className="font-medium">Πατρώνυμο</p>
                                </div>
                                <div className="w-1/2 h-max flex-auto">
                                    <p className="font-medium text-secondary">
                                        {data.profile.fatherName ?? '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                                <div className="w-1/2 h-max flex-auto px-8 my-1">
                                    <p className="font-medium">Μητρώνυμο</p>
                                </div>
                                <div className="w-1/2 h-max flex-auto">
                                    <p className="font-medium text-secondary">
                                        {data.profile.motherName ?? '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                                <div className="w-1/2 h-max flex-auto px-8 my-1">
                                    <p className="font-medium">Έτος γέννησης</p>
                                </div>
                                <div className="w-1/2 h-max flex-auto">
                                    <p className="font-medium text-secondary">
                                        {data.profile.birthYear ?? '-'}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray break-all">
                        <div className="w-1/2 h-max flex-auto px-8 my-1">
                            <p className="font-medium">Email</p>
                        </div>
                        <div className="w-1/2 h-max flex-auto flex justify-between mr-4">
                            {editing ? (
                                <input
                                    className="border-gray-300 rounded-md border-2 bg-gray-100 w-full mr-10 p-2"
                                    type="email"
                                    name="email"
                                    defaultValue={data.email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            ) : (
                                <>
                                    <p className="font-medium text-secondary">
                                        {data.email ?? '-'}
                                    </p>

                                    <button onClick={setEditing}>
                                        <img src={editIcon} alt="edit-button" />
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                        <div className="w-1/2 h-max flex-auto px-8 my-1">
                            <p className="font-medium">Ρόλος</p>
                        </div>
                        <div className="w-1/2 h-max flex-auto">
                            <p className="font-medium text-secondary">
                                {getRoleText(data.role)}
                            </p>
                        </div>
                    </div>
                    <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                        <div className="w-1/2 h-max flex-auto px-8 my-1">
                            <p className="font-medium">Ημερομηνία Εγγραφής</p>
                        </div>
                        <div className="w-1/2 h-max flex-auto">
                            <p className="font-medium text-secondary">
                                {formatDate(data.createdAt)}
                            </p>
                        </div>
                    </div>
                </div>

                {editing && (
                    <div className="flex justify-end m-10">
                        <button
                            className="mr-14  self-auto bg-white text-primary border border-primary hover:bg-primary hover:text-white rounded-lg py-3.5 px-10 transition-all"
                            onClick={() => {
                                setEmail(data.email)
                                setEditing(!editing)
                            }}
                        >
                            Ακύρωση
                        </button>
                        <button
                            className="self-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                            onClick={handleEmailUpdate}
                        >
                            Αποθήκευση
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    )

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            {isMobile ? (
                profileContent
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    {profileContent}
                </Modal>
            )}
        </>
    )
}

export default Profile
