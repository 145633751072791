import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import Loading from '../components/Loading/Loading'
import useFetch from '../hooks/useFetch'

const EmailVerification = () => {
    const vToken = useParams().vToken
    const verifyURL = `${process.env.REACT_APP_API_URL}/auth/mail-verify`

    const { data, loading } = useFetch(verifyURL, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${vToken}`,
        },
    })

    useEffect(() => {
        document.title =
            'Επιβεβαίωση διεύθυνσης email - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
    }, [])

    if (loading || !data) {
        return <Loading />
    }

    return (
        <>
            <Modal classes="w-full max-w-7xl mx-auto flex flex-col">
                <Header
                    includeLogo={false}
                    withBorder={true}
                    includeButtons={true}
                />
                <div className="w-full flex items-center flex-col py-20">
                    <div className="max-w-2xl py-10">
                        {data.success ? (
                            <>
                                <h2 className="text-center font-bold text-3xl mb-10">
                                    Η επιβεβαίωση πραγματοποιήθηκε!
                                </h2>
                                <p className="text-center text-secondary text-2xl">
                                    Πλέον αφού συνδεθείτε, μπορείτε να
                                    χρησιμοποιήσετε όλες τις λειτουργίες της
                                    ιστοσελίδας.
                                </p>
                            </>
                        ) : (
                            <>
                                <h2 className="text-center font-bold text-3xl mb-10">
                                    Η επιβεβαίωση απέτυχε.
                                </h2>
                                <p className="text-center text-secondary text-2xl">
                                    Η επιβεβαίωση της διεύθυνσης email σας
                                    απέτυχε. Παρακαλώ προσπαθήστε ξανά.
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EmailVerification
