import imageToBase64 from 'image-to-base64/browser'

export function formatDate(UTCDate) {
    try {
        const date = new Date(UTCDate)

        return new Intl.DateTimeFormat('el-GR', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }).format(date)
    } catch (err) {
        console.error(err)
        return '---'
    }
}

export function formatAmount(amount) {
    try {
        return new Intl.NumberFormat('el-GR', {
            style: 'currency',
            currency: 'EUR',
        }).format(amount)
    } catch (err) {
        console.error(err)
        return '---'
    }
}

export async function imgToBase64(imagePath) {
    try {
        const imgToBase64Module = await imageToBase64(imagePath)
        const convertedImage = `data:image/png;base64,${imgToBase64Module}`
        return convertedImage
    } catch (error) {
        console.error(error)
    }
}
