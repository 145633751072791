import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import { Link } from 'react-router-dom'

const InstantPaymentFailure = () => {
    return (
        <div className="flex flex-col items-center md:m-20 m-10 md:w-auto w-full">
            <Modal classes="w-full max-w-7xl flex flex-col">
                <Header
                    includeLogo={false}
                    withBorder={true}
                    includeButtons={false}
                />
                <div className="w-full flex items-center flex-col py-20">
                    <div className="max-w-2xl py-10">
                        <h1 className="mb-8 text-xl text-center font-bold">
                            H πληρωμή σας απέτυχε.
                        </h1>

                        <p className="text-center text-secondary text-md">
                            Μπορείτε να προσπαθήσετε ξανά κάνοντας κλικ{' '}
                            <Link
                                to="/instant-payment"
                                className="text-sky-700"
                            >
                                εδώ.
                            </Link>
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default InstantPaymentFailure
