import Header from '../components/Header/Header'
import Modal from '../components/Modal/Modal'
import user404 from '../assets/images/user404.png'

import { useEffect } from 'react'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const Page404 = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    const { isLoggedIn } = useAuth()

    useEffect(() => {
        document.title = 'Η σελίδα δεν βρέθηκε'
    }, [])

    return (
        <>
            <Modal
                classes={`w-3/4 ${
                    isLoggedIn() ? 'max-w-7xl' : 'mx-auto'
                } flex flex-col`}
            >
                {isMobile ? null : (
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />
                )}

                <img src={user404} alt="Η σελίδα δεν βρέθηκε" />
            </Modal>
        </>
    )
}

export default Page404
