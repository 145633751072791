import { useState } from 'react'
import PropTypes from 'prop-types'

import StatusDropDown from '../StatusDropDown/StatusDropDown'
import FormField from '../FormField/FormField'

import '../TableFilters/TableFilters.css'

const MobileTableFilters = ({ kind, setURL, toggleFilters }) => {
    const [inputs, setInputs] = useState({
        afm: '',
        title: '',
        status: '',
    })

    const handleChange = event => {
        const value = event.target.value
        const name = event.target.name

        setInputs(values => ({ ...values, [name]: value }))
    }

    const performSearch = () => {
        const queryURL =
            kind === 'payment'
                ? `${process.env.REACT_APP_API_URL}/payments?taxid=${inputs?.afm}&status=${inputs?.status}&categoryId=${inputs?.categoryId}`
                : `${process.env.REACT_APP_API_URL}/balance-requests?taxid=${inputs?.afm}&status=${inputs?.status}&title=${inputs?.title}`
        setURL(queryURL)
        toggleFilters()
    }

    return (
        <div className="flex flex-col">
            <div className="border-b-2">
                <div className="flex flex-row text-2xl text-primary p-4">
                    <button className=" " onClick={toggleFilters}>
                        &#10006;
                    </button>

                    <div className="flex-1 flex justify-center">
                        <p className="items-center w-fit font-bold self-center pr-5">
                            Φίλτρα
                        </p>
                    </div>
                </div>
            </div>

            <form className="flex flex-col">
                <div className="border-b-2">
                    <div className="p-4 w-2/3">
                        <FormField
                            type="number"
                            labelOnSide={false}
                            name="afm"
                            label="ΑΦΜ Οφειλέτη:"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="border-b-2">
                    <div className="p-4 w-2/3">
                        <FormField
                            type="text"
                            labelOnSide={false}
                            name="title"
                            label="Τίτλος:"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="p-4 pr-9">
                    <StatusDropDown
                        kind={kind}
                        labelOnSide={false}
                        onChange={e =>
                            setInputs(values => ({
                                ...values,
                                ['status']: e.value,
                            }))
                        }
                    />
                </div>

                <button
                    type="submit"
                    onClick={performSearch}
                    className="self-center bg-blue text-white border border-blue hover:bg-white hover:text-blue rounded-lg my-16 py-3.5 px-10 transition-all"
                >
                    Αναζήτηση
                </button>
            </form>
        </div>
    )
}
MobileTableFilters.propTypes = {
    kind: PropTypes.oneOf(['payment', 'balance-request']).isRequired,
    toggleFilters: PropTypes.func,
    setURL: PropTypes.func,
}

export default MobileTableFilters
