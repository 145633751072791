import './HistoryTable.css'
import PropTypes from 'prop-types'

const HistoryTable = ({ headings, rows, noDataText }) => {
    const tableHeadings = headings.map((heading, index) => {
        return <th key={index}> {heading} </th>
    })

    const tableRows = rows.map((row, index) => {
        const values = Object.values(row).map((value, index) => {
            return <td key={index}> {value} </td>
        })

        return <tr key={index}>{values}</tr>
    })

    return (
        <div className="w-full lg:w-fit p-10">
            <table className="history-table">
                <thead>
                    <tr>{tableHeadings}</tr>
                </thead>
                <tbody>
                    {tableRows.length > 0 ? (
                        tableRows
                    ) : (
                        <tr>
                            <td colSpan={tableHeadings.length}>
                                {noDataText ?? 'Δεν βρέθηκαν δεδομένα'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

HistoryTable.propTypes = {
    headings: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    noDataText: PropTypes.string,
}

export default HistoryTable
