import { useRef } from 'react'
import PropTypes from 'prop-types'
import './PaymentAccordion.css'
import NavItem from '../NavItem/NavItem'

const PaymentAccordion = ({ handleToggle, active, closeFunction }) => {
    const userClasses = `
    nav-item
    fw-medium 
    text-lg 
    text-primary 
    mb-7 
    hover:bg-accent 
    hover:text-white 
    py-3 
    px-6 
    rounded-lg 
    transition-all 
`
    const AccRef = useRef()
    return (
        <div className="md:mb-7">
            <div className="md:my-12 rounded-lg overflow-auto  transition-all">
                <div className="acc-header hover:bg-accent hover:text-white">
                    <div
                        className={`acc-toggle  relative ${
                            active ? 'active text-white bg-accent' : ''
                        }`}
                        onClick={() => handleToggle()}
                    >
                        <h5 className="acc-mobile-title md:acc-title md:text-start text-center px-6 py-3 mb-0 relative">
                            Οφειλές
                        </h5>
                    </div>
                </div>
                <div
                    ref={AccRef}
                    className={`collapse ${active ? 'show' : ''}`}
                    style={
                        active
                            ? { height: AccRef.current.scrollHeight - 40 } // <-- Correction needed, subtracting its not a valid sollution
                            : { height: '0px' }
                    }
                >
                    <div className="acc-body text-md">
                        <div className="flex flex-col gap-5">
                            <NavItem
                                classes={userClasses}
                                path="/debts/confirmed"
                                text="Βεβαιωμένες Οφειλές"
                                closeFunction={closeFunction}
                            />
                            <NavItem
                                classes={userClasses}
                                path="/debts/installments"
                                text="Οφειλές σε Ρύθμιση"
                                closeFunction={closeFunction}
                            />
                            <NavItem
                                classes={userClasses}
                                path="/payments"
                                text="Ιστορικό Πληρωμών"
                                closeFunction={closeFunction}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PaymentAccordion.propTypes = {
    handleToggle: PropTypes.func,
    active: PropTypes.bool,
    closeFunction: PropTypes.func,
}

export default PaymentAccordion
