import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { fetchAPI } from '../helpers/api'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import Status from '../enums/Status'

const PaymentFailure = () => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()
    const [queryParams] = useSearchParams()
    const paymentId = queryParams.get('paymentId')

    const link = (
        <Link to="/payment" className=" text-sky-700">
            “Πληρωμή”
        </Link>
    )

    useEffect(() => {
        const updatePayment = async () => {
            const res = await fetchAPI(
                `${process.env.REACT_APP_API_URL}/payments/${paymentId}`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        status: Status.declined,
                    }),
                }
            )
            return res
        }

        if (!paymentId) {
            return
        }

        updatePayment()
        navigate('/payment/failure', { replace: true })
    }, [paymentId])
    return (
        <>
            <Modal classes="w-full max-w-7xl flex flex-col">
                <Header
                    includeLogo={false}
                    withBorder={true}
                    includeButtons={true}
                />
                <div className="w-full flex items-center flex-col py-20">
                    <div className="max-w-2xl py-10">
                        <h1 className="mb-8 text-xl text-center font-bold">
                            H πληρωμή απέτυχε.
                        </h1>

                        <p className="text-center text-secondary text-md">
                            Μπορείτε να προσπαθήσετε ξανά στο&nbsp;
                            {link}.
                        </p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PaymentFailure
