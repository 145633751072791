import Modal from '../components/Modal/Modal'
import Loading from '../components/Loading/Loading'
import Header from '../components/Header/Header'
import StatusText from '../components/StatusText/StatusText'

import { useParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import useMediaQuery from '../hooks/useMediaQuery'

import { formatDate } from '../helpers/formatters'
import { useQuery } from '@tanstack/react-query'
import { getBalanceRequest } from '../api/balance-requests'

const BalanceRequestMore = () => {
    const { token } = useContext(AuthContext)
    const isMobile = useMediaQuery('(max-width:768px)')
    const { id: balanceRequestId } = useParams()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const { isLoading, data: balanceRequestData } = useQuery({
        queryKey: ['balanceRequest', options],
        queryFn: () => getBalanceRequest(balanceRequestId, options),
    })

    useEffect(() => {
        document.title = 'Λεπτομέρειες - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
    }, [])

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            {balanceRequestData.data &&
                (isMobile ? (
                    <>
                        <div className="flex flex-col">
                            <div className="flex flex-col pb-32">
                                <header className="text-3xl text-center py-8">
                                    Λεπτομέρειες Αίτησης Υπολοίπου
                                </header>
                                <Modal classes="w-1/2 pl-8 pr-12 pb-8 pt-20 self-center">
                                    <div className="flex flex-col self-center justify-around">
                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Τίτλος:
                                            </div>
                                            <div className="w-2/3 text-secondary">
                                                {balanceRequestData.data.title}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Ημερομηνία Υποβολής Αίτησης:
                                            </div>
                                            <div className="w-2/3 text-secondary">
                                                {formatDate(
                                                    balanceRequestData.data
                                                        .createdAt
                                                )}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Λεπτομέρειες:
                                            </div>
                                            <div className="w-2/3 text-secondary">
                                                {balanceRequestData.data
                                                    .details === '' ? (
                                                    <p>
                                                        Δεν υπάρχουν ακόμα
                                                        σχόλια για την
                                                        συγκεκριμένη αίτηση
                                                    </p>
                                                ) : (
                                                    balanceRequestData.data
                                                        .details
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Κατάσταση:
                                            </div>

                                            <StatusText
                                                kind="balance-request"
                                                status={
                                                    balanceRequestData.data
                                                        .status
                                                }
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </>
                ) : (
                    <Modal classes="w-full max-w-7xl flex flex-col">
                        <Header
                            includeLogo={false}
                            withBorder={true}
                            includeButtons={true}
                        />

                        <div className="flex flex-col">
                            <div className="flex flex-col pb-32">
                                <header className="text-3xl text-center py-8">
                                    Λεπτομέρειες Αίτησης Υπολοίπου
                                </header>
                                <Modal classes="w-1/2 pl-8 pr-12 pb-8 pt-20 self-center">
                                    <div className="flex flex-col self-center justify-around">
                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Τίτλος:
                                            </div>
                                            <div className="w-2/3 text-secondary">
                                                {balanceRequestData.data.title}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Ημερομηνία Υποβολής Αίτησης:
                                            </div>
                                            <div className="w-2/3 text-secondary">
                                                {formatDate(
                                                    balanceRequestData.data
                                                        .createdAt
                                                )}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Λεπτομέρειες:
                                            </div>
                                            <div className="w-2/3 text-secondary">
                                                {balanceRequestData.data
                                                    .details === '' ? (
                                                    <p>
                                                        Δεν υπάρχουν ακόμα
                                                        σχόλια για την
                                                        συγκεκριμένη αίτηση
                                                    </p>
                                                ) : (
                                                    balanceRequestData.data
                                                        .details
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-base text-primary mb-14 flex gap-6 items-center w-full font-bold">
                                            <div className="w-1/3 text-right">
                                                Κατάσταση:
                                            </div>

                                            <StatusText
                                                kind="balance-request"
                                                status={
                                                    balanceRequestData.data
                                                        .status
                                                }
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </Modal>
                ))}
        </>
    )
}

export default BalanceRequestMore
