import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Modal from '../components/Modal/Modal'
import FormField from '../components/FormField/FormField'

import userIcon from '../assets/images/user-icon.svg'
import taxisIcon from '../assets/images/taxis-icon.png'

import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const Login = () => {
    const { isLoggedIn, login } = useAuth()

    const [inputs, setInputs] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [errorMsgTaxis, setErrorMsgTaxis] = useState(false)
    const [errorMsgAdmin, setErrorMsgAdmin] = useState(false)

    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Σύνδεση - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
        if (isLoggedIn()) {
            navigate('/')
        }
    }, [])

    const handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        await login(inputs.email, inputs.password)

        if (!isLoggedIn()) {
            setErrorMsgAdmin(true)
        }
    }

    const divClasses = isMobile
        ? 'py-8 flex flex-col items-center'
        : 'py-16 px-28 text-center'

    const loginBody = (
        <div className="flex flex-col items-center md:m-20 m-10 md:w-auto w-full">
            <div className="bg-accent rounded-full w-fit p-6 md:my-10 md:self-center">
                <img width="30" height="30" src={userIcon} alt="user" />
            </div>

            <form
                className="flex flex-col w-full px-12 md:px-0"
                onSubmit={handleSubmit}
            >
                <FormField
                    type="email"
                    label="Email"
                    name="email"
                    labelOnSide={false}
                    onChange={handleChange}
                />
                <FormField
                    type="password"
                    label="Κωδικός"
                    name="password"
                    labelOnSide={false}
                    onChange={handleChange}
                />

                {errorMsgAdmin ? (
                    <div className=" self-start text-left underline text-red-700 text-sm pb-7">
                        Το email ή ο κωδικός που εισάγατε είναι λανθασμένος
                    </div>
                ) : null}

                <div className="flex md:gap-8 justify-between md:py-12">
                    <button
                        type="submit"
                        className="m-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                    >
                        Σύνδεση
                    </button>
                </div>
            </form>
        </div>
    )

    const TaxisLogin = () => (
        <>
            <main className="flex justify-center items-center">
                <div className="py-16 px-10 text-center flex flex-col items-center">
                    <p className="font-light text-secondary text-l md:text-xl">
                        Αποκτήστε πρόσβαση σε προσωποποιημένες υπηρεσίες,
                        δημιουργήστε έναν λογαριασμό και παρακολουθήστε την
                        κατάσταση αιτημάτων καθώς και την θυρίδα σας. Για πλήρη
                        πρόσβαση στις e-Υπηρεσίες, συνδεθείτε μέσω TAXIS NET.
                    </p>

                    <div className="bg-accent rounded-full w-fit p-4 my-10">
                        <img width="30" height="30" src={userIcon} alt="user" />
                    </div>
                    <Link
                        className="flex items-center gap-2 text-accent font-semibold text-lg shadow-xl rounded-xl px-4 py-2 border-2 border-black  "
                        to="/under-construction"
                    >
                        Σύνδεση με
                        <img src={taxisIcon} alt="TaxisNet" />
                    </Link>
                    {errorMsgTaxis ? (
                        <div className=" underline font-semibold text-red-700 text-base pt-10">
                            Η διαδικασία ταυτοποίησης μέσω taxis-net απέτυχε.
                        </div>
                    ) : null}
                </div>
            </main>
        </>
    )

    return (
        <>
            <main className="flex justify-center items-center m-3">
                <Modal classes=" w-full h-fit m-5 md:max-w-5xl md:m-12">
                    <Header includeLogo={false} withBorder={true} />

                    <div className={divClasses}>
                        <h1 className="font-bold  text-primary text-3xl ">
                            Είσοδος
                        </h1>
                        <div className="border-b-2 w-full">
                            <TaxisLogin />
                        </div>

                        {isMobile ? (
                            loginBody
                        ) : (
                            <Modal classes="max-w-10xl lg:m-24 min-w-max mx-8">
                                {loginBody}
                            </Modal>
                        )}
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default Login

// OLD LOGIN IS BELOW WAITING TO RETURN..

/**
 * import Header from '../components/Header/Header'
 * import Footer from '../components/Footer/Footer'
 * import Modal from '../components/Modal/Modal'
 *
 * import userIcon from '../assets/images/user-icon.svg'
 * import taxisIcon from '../assets/images/taxis-icon.png'
 *
 * import { useEffect, useState } from 'react'
 * import { useNavigate } from 'react-router-dom'
 *
 * import useAuth from '../hooks/useAuth'
 *
 * const Login = () => {
 *     const { isLoggedIn, login } = useAuth()
 *     const [errorMsg, setErrorMsg] = useState(false)
 *     const navigate = useNavigate()
 *
 *     useEffect(() => {
 *         if (isLoggedIn()) {
 *             navigate('/')
 *         }
 *     }, [])
 *
 *     useEffect(() => {
 *         document.title = 'Σύνδεση - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
 *     }, [])
 *
 *     const handleLogin = async () => {
 *         await login()
 *         if (!isLoggedIn()) {
 *             setErrorMsg(true)
 *         }
 *     }
 *
 *     return (
 *         <>
 *             <main className="flex justify-center items-center">
 *                 <Modal classes="max-w-5xl mx-4 my-10">
 *                     <Header includeLogo={false} withBorder={true} />
 *                     <div className="py-16 px-10 text-center flex flex-col items-center">
 *                         <h1 className="font-bold text-primary text-3xl mb-8">
 *                             Είσοδος
 *                         </h1>
 *
 *                         <p className="font-light text-secondary text-l md:text-xl">
 *                             Αποκτήστε πρόσβαση σε προσωποποιημένες υπηρεσίες,
 *                             δημιουργήστε έναν λογαριασμό και παρακολουθήστε την
 *                             κατάσταση αιτημάτων καθώς και την θυρίδα σας. Για
 *                             πλήρη πρόσβαση στις e-Υπηρεσίες, συνδεθείτε μέσω
 *                             TAXIS NET.
 *                         </p>
 *
 *                         <div className="bg-accent rounded-full w-fit p-4 my-10">
 *                             <img
 *                                 width="30"
 *                                 height="30"
 *                                 src={userIcon}
 *                                 alt="user"
 *                             />
 *                         </div>
 *
 *                         <button
 *                             className="flex items-center gap-2 text-primary shadow-xl rounded-xl px-4 py-2"
 *                             onClick={() => handleLogin()}
 *                         >
 *                             Σύνδεση με
 *                             <img src={taxisIcon} alt="TaxisNet" />
 *                         </button>
 *                         {errorMsg ? (
 *                             <div className=" underline font-semibold text-red-700 text-base pt-10">
 *                                 Η διαδικασία ταυτοποίησης μέσω taxi-net
 *                                 απέτυχε...
 *                             </div>
 *                         ) : null}
 *                     </div>
 *                 </Modal>
 *             </main>
 *
 *             <Footer />
 *         </>
 *     )
 * }
 *     )
 * }
 */
