import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import UnderConstructionModal from '../components/UnderConstructionModal/UnderConstructionModal'

const UnderConstruction = () => {
    return (
        <>
            <Header
                includeLogo={false}
                withBorder={false}
                includeButtons={true}
            />

            <UnderConstructionModal />

            <Footer />
        </>
    )
}

export default UnderConstruction
