import Header from '../components/Header/Header'
import Modal from '../components/Modal/Modal'
import PaymentForm from '../components/PaymentForm/PaymentForm'

import useMediaQuery from '../hooks/useMediaQuery'

import { useEffect } from 'react'

const Payment = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        document.title = 'Πληρωμή Οφειλής - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
    }, [])

    return (
        <>
            <Modal classes="w-full max-w-7xl  flex flex-col h-fit md:h-auto mx-6 md:mx-6">
                {isMobile ? null : (
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />
                )}
                <div className="flex flex-col md:justify-center items-center grow">
                    <PaymentForm />
                </div>
            </Modal>
        </>
    )
}

export default Payment
