import useAuth from '../../../hooks/useAuth'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

import MobileLogo from '../MobileLogo/MobileLogo'
import HeaderButtons from '../../HeaderButtons/HeaderButtons'
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import TokenCountdown from '../../TokenCountdown/TokenCountdown'

const MobileHeader = ({ isModalHeader = false }) => {
    const { isLoggedIn, isAdmin } = useAuth()
    const location = useLocation()

    return (
        <>
            {isLoggedIn() ? (
                <>
                    <div
                        id="mobile-header"
                        className={`flex w-full space-between p-5 items-center border-b-2 border-primary z-10
                        ${
                            isAdmin()
                                ? 'bg-primary rounded-b-[30px]'
                                : 'bg-[#f0f3f4]'
                        }`}
                    >
                        {isModalHeader ? (
                            <MobileLogo />
                        ) : (
                            <>
                                <BurgerMenu />
                                <MobileLogo />

                                <HeaderButtons
                                    includeLogout={false}
                                    includeProfile={!isAdmin()}
                                />
                            </>
                        )}
                    </div>

                    <div className="flex justify-center mt-8">
                        <TokenCountdown />
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-col bg-white border-b-2 border-primary rounded-t-3xl">
                        <MobileLogo />

                        {location.pathname === '/' ? (
                            <div className="flex gap-3 justify-center my-5 flex-wrap">
                                <Link
                                    className="bg-primary text-white w-full max-w-[40%] text-center border border-primary hover:bg-white hover:text-primary rounded-md p-3"
                                    to="/login"
                                >
                                    Σύνδεση
                                </Link>
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </>
    )
}

MobileHeader.propTypes = {
    isModalHeader: PropTypes.bool,
}

export default MobileHeader
