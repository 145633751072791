import Modal from '../../components/Modal/Modal'
import StatusDropDown from '../../components/StatusDropDown/StatusDropDown'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import Loading from '../../components/Loading/Loading'

import { useContext, useRef, useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { formatDate } from '../../helpers/formatters'
import {
    getBalanceRequest,
    updateBalanceRequest,
} from '../../api/balance-requests'

const AdminBalanceRequestEdit = () => {
    useEffect(() => {
        document.title =
            'Επεξεργασία Αίτησης Οφειλής - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
    }, [])

    const statusRef = useRef(null)
    const navigate = useNavigate()
    const [detailsValue, setDetailsValue] = useState('')
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { id: balanceRequestId } = useParams()
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['adminBalanceRequest', balanceRequestId, options],
        queryFn: () => getBalanceRequest(balanceRequestId, options),
    })

    const mutation = useMutation({
        mutationFn: updatedBalanceRequest =>
            updateBalanceRequest(balanceRequestId, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedBalanceRequest),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['adminBalanceRequest'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Οι αλλαγές υποβλήθηκαν με επιτυχία! Μπορείτε να δείτε τη
                        νέα κατάσταση της αίτησης στον πίνακα με τις&nbsp;
                        <Link
                            className="font-bold"
                            to={'/admin/balance-request'}
                        >
                            &quot;Αιτήσεις Υπολοίπου&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))

            setTimeout(() => {
                navigate('/admin/balance-request')
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η αίτηση υπολοίπου δεν ενημερώθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const balanceRequestData = data.data

    return (
        balanceRequestData && (
            <>
                {popup.shown && (
                    <PopupMessage
                        message={popup.message}
                        type={popup.type}
                        closePopUp={() =>
                            setPopup(values => ({ ...values, shown: false }))
                        }
                        showCloseButton={true}
                    />
                )}

                <div className="flex flex-col py-10 md:py-0">
                    <div className="flex flex-col pb-32">
                        <header className="text-3xl text-center py-12">
                            Επεξεργασία Αίτησης Υπολοίπου
                        </header>

                        <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                            <div className="flex flex-col self-center justify-around">
                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        ΑΦΜ Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {balanceRequestData.user.profile.taxid}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ονοματεπώνυμο Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {`${balanceRequestData.user.profile.firstName} ${balanceRequestData.user.profile.lastName}`}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Τίτλος:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {balanceRequestData.title}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Υποβολής Αίτησης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(
                                            balanceRequestData.createdAt
                                        )}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Ενημέρωσης Αίτησης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(
                                            balanceRequestData.updatedAt
                                        )}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Λεπτομέρειες:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        <textarea
                                            className="border w-full"
                                            defaultValue={
                                                balanceRequestData.details
                                            }
                                            onChange={e =>
                                                setDetailsValue(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <StatusDropDown
                                    status={balanceRequestData.status}
                                    kind="balance-request"
                                    reference={statusRef}
                                />

                                <button
                                    className="self-end  mt-14 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10"
                                    type="submit"
                                    onClick={() =>
                                        mutation.mutate({
                                            status: statusRef.current.props
                                                .value.value,
                                            details: detailsValue,
                                        })
                                    }
                                    disabled={mutation.isLoading}
                                >
                                    Αποθήκευση
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        )
    )
}

export default AdminBalanceRequestEdit
