import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Modal from '../../components/Modal/Modal'

import userIcon from '../../assets/images/user-icon.svg'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import FormField from '../../components/FormField/FormField'

const AdminLogin = () => {
    const { isLoggedIn, login } = useAuth()
    const [inputs, setInputs] = useState({})
    const [errorMsg, setErrorMsg] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Σύνδεση - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
        if (isLoggedIn()) {
            navigate('/admin')
        }
    }, [])

    const handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async e => {
        const isAdmin = true
        e.preventDefault()
        await login(inputs.email, inputs.password, isAdmin)
        if (!isLoggedIn()) {
            setErrorMsg(true)
        }
    }

    return (
        <>
            <main className="flex justify-center bg-primary ">
                <Modal classes="max-w-5xl w-full  m-12">
                    <Header includeLogo={false} withBorder={true} />
                    <div className="py-16 px-28 text-center">
                        <h1 className="font-bold text-primary text-3xl">
                            Είσοδος
                        </h1>
                        <Modal classes="max-w-10xl m-24 px-8">
                            <div className="flex-col flex m-20">
                                <div className="bg-accent rounded-full w-fit p-6 my-10 self-center">
                                    <img
                                        width="30"
                                        height="30"
                                        src={userIcon}
                                        alt="user"
                                    />
                                </div>

                                <form
                                    className="flex flex-col"
                                    onSubmit={handleSubmit}
                                >
                                    <FormField
                                        type="email"
                                        label="Username ή Email"
                                        name="email"
                                        labelOnSide={false}
                                        onChange={handleChange}
                                    />
                                    <FormField
                                        type="password"
                                        label="Κωδικός"
                                        name="password"
                                        labelOnSide={false}
                                        onChange={handleChange}
                                    />

                                    {errorMsg ? (
                                        // TODO: Discuss if error div should be replaced with an error popup
                                        <div className=" self-start text-left underline text-red-700 text-sm pb-7">
                                            Το email ή ο κωδικός που εισάγατε
                                            είναι λανθασμένος
                                        </div>
                                    ) : null}
                                    <div className="flex flex-row justify-between py-12">
                                        <button
                                            type="submit"
                                            className="self-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                                        >
                                            Σύνδεση
                                        </button>
                                        <button className="self-auto bg-blue text-white border border-blue hover:bg-white hover:text-blue rounded-lg py-3.5 px-10 transition-all">
                                            Εγγραφή
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default AdminLogin
