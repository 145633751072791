import _fetch from '.'

export async function getPaymentsOfUser(userId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}/payments`
    return _fetch(url, options)
}

export async function getPayment(paymentId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/payments/${paymentId}`
    return _fetch(url, options)
}

export async function getAllPayments(filters, options = {}) {
    const { afm: taxid, status, title } = filters

    const url = new URL(`${process.env.REACT_APP_API_URL}/payments/`)

    url.searchParams.set('taxid', taxid)
    url.searchParams.set('status', status)
    url.searchParams.set('title', title)

    return _fetch(url, options)
}

export async function updatePayment(paymentId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/payments/${paymentId}`
    return _fetch(url, options)
}
