import './UnderConstructionModal.css'

import Modal from '../Modal/Modal'
import underConst from '../../assets/images/under-construction.png'
import { Link } from 'react-router-dom'

const UnderConstructionModal = () => {
    return (
        <div className="background flex justify-center">
            <Modal classes="my-20 mx-10 md:mx-20   ">
                <div className="flex flex-col">
                    <header className="font-semibold text-3xl text-center border-b border-primary py-6 self-stretch">
                        Σελίδα Υπό Κατασκευή
                    </header>
                    <div className="flex flex-col items-center px-4 md:px-14 py-6">
                        <div className="p-8">
                            <img className="" src={underConst} alt="balance" />
                        </div>
                        <Modal classes="flex flex-col md:flex-row justify-between md:gap-20 gap-5 py-5 px-8">
                            <p className="lg:text-xl md:text-l text-sm font-semibold md:w-2/3 ">
                                Μπορείτε να προχωρήσετε σε πληρωμή μέσω του
                                GovPay Instant, χωρίς να χρειαστεί
                                σύνδεση/εγγραφή.
                            </p>
                            <Link
                                to="/instant-payment"
                                className="self-center bg-accent text-white border border-accent hover:bg-white hover:text-accent rounded-lg py-3.5 px-10 transition-all"
                            >
                                GovPay Instant
                            </Link>
                        </Modal>
                        <div className="flex flex-col md:flex-row self-center p-10 gap-10">
                            <p className="self-center text-center md:text-lg">
                                Επιστροφή στην αρχική σελίδα
                            </p>
                            <Link
                                to="/"
                                className="self-center bg-white text-blue border border-blue hover:bg-blue hover:text-white rounded-lg py-3.5 px-4 md:px-10 transition-all"
                            >
                                Επιστροφή
                            </Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default UnderConstructionModal
