import { useState } from 'react'
import PropTypes from 'prop-types'
import StatusDropDown from '../StatusDropDown/StatusDropDown'

import '../TableFilters/TableFilters.css'

// TODO: Make filters work dynamically

const TableFilters = ({ kind, filterValues, handleFilterChange }) => {
    const [values, setValues] = useState({
        afm: '',
        title: '',
        status: '',
    })

    const handleChange = e => {
        const name = e.target?.name ?? 'status'
        const value = e.target?.value ?? e.value

        setValues(prevValues => ({ ...prevValues, [name]: value }))
        handleFilterChange({ ...values, [name]: value })
    }

    const clearFilters = () => {
        setValues({
            afm: '',
            title: '',
            status: '',
        })

        handleFilterChange({
            afm: '',
            title: '',
            status: '',
        })
    }

    return (
        <>
            <div className="w-full max-w-7xl mt-12 filterBar p-5 rounded-xl">
                <form className="flex justify-around">
                    <div className="flex gap-4 items-center">
                        <label>ΑΦΜ Οφειλέτη:</label>
                        <input
                            type="text"
                            name="afm"
                            onChange={handleChange}
                            value={filterValues.afm}
                        />
                    </div>

                    <div className="flex gap-4 items-center">
                        <label>Τίτλος:</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleChange}
                            value={filterValues.title}
                        />
                    </div>

                    <div className="flex gap-4 items-center">
                        <StatusDropDown
                            kind={kind}
                            value={filterValues.status}
                            onChange={e => {
                                handleChange(e)
                            }}
                        />
                    </div>
                </form>
            </div>

            <button className="mt-5 underline" onClick={clearFilters}>
                Καθαρισμός φίλτρων
            </button>
        </>
    )
}

TableFilters.propTypes = {
    kind: PropTypes.oneOf(['payment', 'balance-request']).isRequired,
    handleFilterChange: PropTypes.func,
    filterValues: PropTypes.object,
}

export default TableFilters
