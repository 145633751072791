import HistoryTable from '../../components/HistoryTable/HistoryTable'
import StatusText from '../../components/StatusText/StatusText'
import TableFilters from '../../components/TableFilters/TableFilters'
import Loading from '../../components/Loading/Loading'
import HistoryItem from '../../components/mobile-components/HistoryItem/HistoryItem'
import MobileTableFilters from '../../components/TableFilters/MobileTableFilters'
import EditButton from '../../components/TableButtons/EditButton'

import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../../contexts/AuthContext'
import { formatDate } from '../../helpers/formatters'
import { getAllBalanceRequests } from '../../api/balance-requests'
import useMediaQuery from '../../hooks/useMediaQuery'

const BalanceRequest = () => {
    useEffect(() => {
        document.title =
            'Διαχείριση αιτήσεων υπολοίπου - Πλατφόρμα Ηλ. Πληρωμών Δήμου'
    }, [])

    const isMobile = useMediaQuery('(max-width:768px)')
    const { token } = useContext(AuthContext)
    const [filterValues, setFilterValues] = useState({
        afm: '',
        title: '',
        status: '',
    })
    const [showFilters, setShowFilters] = useState(false)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const handleFilterChange = newFilters => {
        setFilterValues(newFilters)
    }

    const { isLoading, data: balanceRequests } = useQuery({
        queryKey: ['adminBalanceRequests', options, filterValues],
        queryFn: () => getAllBalanceRequests(filterValues, options),
    })

    if (isLoading) {
        return <Loading />
    }

    const headings = [
        'Τίτλος',
        'ΑΦΜ Οφειλέτη',
        'Ονοματεπώνυμο Οφειλέτη',
        'Ημερομηνία Υποβολής Αίτησης',
        'Ημερομηνία Ενημέρωσης Αίτησης',
        'Κατάσταση',
        'Επεξεργασία',
    ]

    const rows = balanceRequests.data.map(request => {
        return {
            0: request.title ?? '-',
            1: request.user.profile.taxid ?? '-',
            2:
                request.user.profile.firstName && request.user.profile.lastName
                    ? `${request.user.profile.firstName} ${request.user.profile.lastName}`
                    : '-',
            3: formatDate(request.createdAt) ?? '-',
            4: formatDate(request.updatedAt) ?? '-',
            5: (
                <StatusText
                    key={7}
                    kind="balance-request"
                    status={request.status}
                />
            ),
            6: <EditButton key={8} id={request.id} kind="balance-request" />,
        }
    })

    if (showFilters) {
        return (
            // TODO: Fix mobile filters functionality
            <MobileTableFilters
                kind="balance-request"
                toggleFilters={() => setShowFilters(!showFilters)}
                // setURL={setURL}
            />
        )
    }

    return (
        <div className="flex flex-col items-center m-10">
            <h2 className="text-primary text-3xl text-center font-light mt-6 md:mt-24 mb-6">
                Αιτήσεις Υπολοίπου
            </h2>

            {isMobile ? (
                <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="self-auto bg-blue text-white border border-blue hover:bg-white hover:text-blue rounded-lg py-3.5 px-10 transition-all"
                >
                    Φίλτρα
                </button>
            ) : (
                <TableFilters
                    kind="balance-request"
                    filterValues={filterValues}
                    handleFilterChange={handleFilterChange}
                />
            )}

            {isMobile ? (
                <>
                    {balanceRequests.data.length > 0 ? (
                        balanceRequests.data.map((request, index) => {
                            return (
                                <HistoryItem
                                    key={request.id}
                                    headings={headings}
                                    rows={rows[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center py-20">
                            Δεν βρέθηκαν αιτήσεις
                        </h2>
                    )}
                </>
            ) : (
                <>
                    <div className="flex flex-col">
                        <div className="my-10 w-full">
                            <HistoryTable
                                headings={headings}
                                rows={rows}
                                noDataText="Δεν βρέθηκαν αιτήσεις"
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default BalanceRequest
