import './HomeLoggedOut.css'

import balance from '../../assets/images/upoloipo.svg'
import payment from '../../assets/images/ofeili.svg'
import instant from '../../assets/images/instant.png'

import { Link } from 'react-router-dom'

const MobileTopPart = () => {
    return (
        <>
            <div className="w-full flex homeLoggedOut">
                <div className="w-1/3 flex-auto px-8 my-10 boxes-center">
                    <div className="flex flex-col h-full justify-center">
                        <p className="text-center pb-16 px-10 font-bold">
                            Η ηλεκτρονική υπηρεσία GovPay απευθύνεται σε πολίτες
                            και επιχειρήσεις του Δήμου και έχει ως στόχο την
                            απλοποίηση των διαδικασιών σε μια σειρά θεμάτων
                            πληρωμών που σχετίζονται με το Δήμο, όπως είναι το
                            παράβολο πολιτικού γάμου, τα τέλη άρδευσης κ.ά.
                        </p>
                        <div className="w-full flex flex-col gap-16 justify-center px-5">
                            <div className="box p-4 bg-white text-center self-center w-full max-w-[400px]">
                                <Link
                                    to="/payment"
                                    className="flex items-center justify-center gap-3"
                                >
                                    <img
                                        className="max-h-16"
                                        src={payment}
                                        alt="ofeili"
                                    />
                                    <h2 className="text-xl font-bold text-accent">
                                        Πληρωμή Οφειλής
                                    </h2>
                                </Link>
                            </div>
                            <div className="box p-4 bg-white text-center self-center w-full max-w-[400px]">
                                <Link
                                    to="/balance-requests"
                                    className="flex items-center gap-3"
                                >
                                    <img
                                        className="max-h-16"
                                        src={balance}
                                        alt="aitisi"
                                    />
                                    <h2 className="text-xl font-bold text-accent">
                                        Αίτηση Υπολοίπου
                                    </h2>
                                </Link>
                            </div>
                            <div className="flex-col flex gap-4">
                                <p className="text-center font-bold">
                                    Με την επιλογή GovPay Instant, μπορείτε να
                                    προχωρήσετε σε πληρωμή χωρίς τη διαδικασία
                                    της σύνδεσης/εγγραφής.
                                </p>
                                <div className="box p-4 bg-white text-center self-center w-full max-w-[400px]">
                                    <Link
                                        to="/instant-payment"
                                        className="flex items-center justify-center gap-3"
                                    >
                                        <img
                                            className="max-h-16"
                                            src={instant}
                                            alt="ofeili"
                                        />
                                        <h2 className="text-xl font-bold text-accent">
                                            GovPay Instant
                                        </h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileTopPart
