import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import InstantPaymentForm from '../components/InstantPaymentForm/InstantPaymentForm'
import useAuth from '../hooks/useAuth'

const InstantPayment = () => {
    const { isLoggedIn } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Instant Payments - Πλατφόρμα Ηλ. Πληρωμών Δήμου'

        if (isLoggedIn()) {
            navigate('/')
        }
    }, [])

    return (
        <>
            <Header
                includeLogo={false}
                withBorder={false}
                includeButtons={true}
            />

            <InstantPaymentForm />

            <Footer />
        </>
    )
}

export default InstantPayment
